import React, {useState, useEffect, useContext, useMemo} from 'react'
import PropTypes from 'prop-types'
import {getAssetUrl} from 'progressive-web-sdk/dist/ssr/universal/utils'
import {GlobalStateContext, GlobalDispatchContext, STORE_PROPS} from '../../components/global-state'
import {addGenerateLeadEvent} from '../../utils/gtmUtils'
import {Helmet} from 'react-helmet'
import PageTemplateWithData from '../../components/page-template-with-data'
import PageTemplateSkeleton from './skeleton'
import {getPageStructure} from './helper'
import {GET_ENV} from '../../connector'
import {breadcrumbArray, isBrowser, pushDatalayerEvent, isEqual, pageView} from '../../utils/utils'
import {getFromLocal, getFromSession, getCookie} from '../../utils/storage-utils'
import {useEinstein} from '../../hooks/useEinstein'
import {PAGE_TITLES} from '../../utils/constants'

const Home = (props) => {
    let {pageStructureTemp, allSlotsDataTemp, metaTemp, loaded = false} = props
    let pageStructure = pageStructureTemp
    let allSlotsData = allSlotsDataTemp
    let meta = metaTemp

    const [pageDataPushed, setPageDataPushed] = useState(false)

    const [isLoadedFully, setIsLoadedFully] = useState(loaded)
    const baseUrl = 'https://www.whsmith.co.uk'

    const globalState = useContext(GlobalStateContext)
    const dispatch = useContext(GlobalDispatchContext)
    const einstein = useEinstein()

    const layout = useMemo(() => {
        return globalState['homepageTemplate']
    }, [globalState['homepageTemplate']])

    const cachedPageStructure = useMemo(() => {
        return globalState['home']?.pageStructure || pageStructure
    }, [pageStructure, globalState['home']?.pageStructure])

    const cachedAllSlotsData = useMemo(() => {
        return globalState['home']?.allSlotsData || allSlotsData
    }, [allSlotsData, globalState['home']?.allSlotsData])

    const cachedMeta = useMemo(() => {
        return globalState['home']?.meta || meta
    }, [meta, globalState['home']?.meta])

    useEffect(() => {
        setIsLoadedFully(!!loaded)
    }, [loaded])

    useEffect(() => {
        pageView(PAGE_TITLES.HOME, 'homepage', 'Home')
    }, [])

    useEffect(() => {
        if (
            !(
                globalState['home']?.pageStructure &&
                isEqual(globalState['home']?.pageStructure, pageStructure) &&
                globalState['home']?.allSlotsData &&
                isEqual(globalState['home']?.allSlotsData, allSlotsData) &&
                globalState['home']?.meta &&
                isEqual(globalState['home']?.meta, meta)
            )
        ) {
            dispatch({
                type: STORE_PROPS,
                payload: {
                    ['home']: {
                        ...(globalState['home'] || {}),
                        pageStructure: pageStructure,
                        allSlotsData: allSlotsData,
                        meta: meta
                    }
                }
            })
        }
    }, [
        isEqual(globalState['home']?.pageStructure || {}, pageStructure || {}),
        isEqual(globalState['home']?.allSlotsData || {}, allSlotsData || {}),
        isEqual(globalState['home']?.meta || {}, meta || {})
    ])

    useEffect(() => {
        if (isBrowser() && !pageDataPushed) {
            $(window).bind('beforeunload', function() {
                const path = window.location.pathname
                sessionStorage.setItem(path + '-slideTo', $(window).scrollTop())
            })
            let analyticbasketInfo = ''
            if (typeof window !== 'undefined') {
                analyticbasketInfo = getFromLocal('analyticBasket')

                if (analyticbasketInfo != undefined && analyticbasketInfo.length) {
                    analyticbasketInfo = JSON.parse(analyticbasketInfo)
                } else {
                    analyticbasketInfo = {
                        cartValue: 0,
                        cartItemsNum: 0,
                        cartUniqueItemsNum: 0
                    }
                }
            } else {
                analyticbasketInfo = {
                    cartValue: 0,
                    cartItemsNum: 0,
                    cartUniqueItemsNum: 0
                }
            }

            const analyticBreadcrumb = breadcrumbArray()
            const analyticData = {
                event: 'pageDataPushed',
                templateName: 'home-details-page',
                pageType: 'home',
                pageURL: window.location.href,
                pageTitle: meta?.title || 'Books, stationery, gifts and much more | WHSmith',
                originalLocation:
                    getFromSession('originalLocation') !== ''
                        ? getFromSession('originalLocation')
                        : window.location.href,
                visitorStatus:
                    getCookie('isUserLoggedIn') && getCookie('isUserLoggedIn') === 'true'
                        ? 'regular-logged'
                        : 'guest',
                userId: getCookie('customerId') ? getCookie('customerId') : null,
                cartValue: analyticbasketInfo.cartValue,
                cartItemsNum: analyticbasketInfo.cartItemsNum,
                cartUniqueItemsNum: analyticbasketInfo.cartUniqueItemsNum
            }
            if (Object.keys(analyticBreadcrumb).length > 0) {
                analyticData['breadcrumb'] = analyticBreadcrumb
            }
            pushDatalayerEvent(analyticData)
            setPageDataPushed(true)
        }
    })

    useEffect(() => {
        if (isBrowser()) {
            einstein.sendViewPage(location?.pathname)
        }
    }, [einstein.einsteinEnabled])

    return (
        <>
            {baseUrl !== null && (
                <Helmet
                    htmlAttributes={{
                        lang: 'en-US',
                        'data-reactroot': ''
                    }}
                >
                    <script type="application/ld+json">
                        {`{"@context": "https://schema.org",
                        "@graph":[
                        {
                        "@type": "Corporation",
                        "@id": "${baseUrl}/#corporation",
                        "name": "WHSmith",
                        "url": "${baseUrl}/",
                        "logo": "${process.env.HOSTNAME}${getAssetUrl(
                            'static/svg/sprite-source/whsmith-logo.svg'
                        )}",
                        "sameAs": [ "https://www.facebook.com/WHSmithuk", "https://twitter.com/WHSmith",
                        "https://www.instagram.com/whsmithofficial", "https://www.youtube.com/user/whsmithdirect",
                        "https://www.linkedin.com/company/whsmith", "https://www.pinterest.com/whsmithcouk", "https://blog.whsmith.co.uk/" ]
                        }
                         ]}`}
                    </script>
                    <script type="application/ld+json">{`
                {
                    "@context": "https://schema.org",
                    "@graph": [
                        {
                            "@type": "WebPage",
                            "@id": "${baseUrl}/#webpage",
                            "url": "${baseUrl}",
                            "name": "Books, stationery, gifts and much more.",
                            "isPartOf": {
                              "@id": "${baseUrl}/#website"
                            },
                            "about": {
                              "@id": "${baseUrl}/#corporation"
                            },
                            "datePublished": "",
                            "dateModified": "",
                            "inLanguage": "en-GB"
                          }
                    ]
                }
            `}</script>
                </Helmet>
            )}

            {isLoadedFully ? (
                <>
                    <PageTemplateWithData
                        pageStructure={cachedPageStructure}
                        allSlotsData={cachedAllSlotsData}
                        title={cachedMeta && cachedMeta.title}
                        meta={cachedMeta}
                        pageDataPushed={pageDataPushed}
                        setIsLoadedFully={setIsLoadedFully}
                    />
                </>
            ) : (
                <PageTemplateSkeleton
                    layout={
                        /new-homepage-\S*/.exec(layout || '')?.length > 0
                            ? /new-homepage-\S*/.exec(layout || '')[0]
                            : 'homepage'
                    }
                />
            )}
        </>
    )
}

Home.getTemplateName = () => {
    return 'home-page'
}

Home.shouldGetProps = ({previousParams, params}) => {
    return !previousParams || previousParams.catUrl !== params.catUrl
}

Home.getProps = async (props) => {
    const {connector, res} = props
    let contentAssetId = 'mp-homepage'
    if (GET_ENV === 'production' && res) {
        res.set('cache-control', `max-age=86400, s-maxage=86400`)
    }

    let pageStructure = getPageStructure(connector, contentAssetId)

    return pageStructure
}

Home.propTypes = {
    errorMessage: PropTypes.string,
    params: PropTypes.object,
    pageStructure: PropTypes.object,
    allSlotsData: PropTypes.any,
    trackPageLoad: PropTypes.func,
    history: PropTypes.any,
    meta: PropTypes.any,
    pageDataPushed: PropTypes.any,
    loaded: PropTypes.any
}

export default Home
